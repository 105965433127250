/*Root*/
:root {
  --fondoMain: #eeeeee;
  --colorPrincipal: #00a04c;
  --principalOscuro: #086635;
  --principalLight: #acf3ce;
  --principalSoft: #a4d1f2;
  --colorSecundario: #1471b7;
  --secundarioLight: #43aeff;
  --secundarioSoft: #9ecff5;
  --botonAgotado: #b95050;
  --hoverTabla: #cccccc;
}

/*OVERRIDES BOOTSTRAP*/

/*Botón primario*/
.badge.bg-success {
  background-color: var(--principalOscuro) !important;
}

.badge.bg-danger {
  background-color: var(--botonAgotado) !important;
}

.btn-primary {
  background-color: var(--colorSecundario) !important;
  border-color: var(--secundarioLight) !important;
}

.btn-primary {
  background-color: var(--colorSecundario) !important;
  border-color: var(--secundarioLight) !important;
  margin-bottom: 3px;
}

.btn-secondary {
  background-color: var(--colorSecundario) !important;
  border-color: var(--colorSecundarioSoft) !important;
  color: white !important;
  margin-bottom: 5px !important;
}

.btn-secondary:hover {
  background-color: var(--principalLight) !important;
  border-color: var(--colorPrincipal) !important;
}

.btn-secondary-agotado {
  background-color: var(--botonAgotado) !important;
  border-color: var(--botonAgotado) !important;
  opacity: 1 !important;
}

/*Botón primario Hover*/
.btn-primary:hover {
  background-color: var(--secundarioLight) !important;
  border-color: var(--colorSecundario) !important;
}
/*Cabecera accordions*/
.accordion-button:not(.collapsed) {
  color: var(--principalSoft) !important;
  background-color: var(--colorPrincipal) !important;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
.accordion-button {
  color: var(--principalOscuro) !important;
  background-color: var(--principalSoft) !important;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--hoverTabla) !important;
  color: var(--colorPrincipal) !important;
}

.formVisitaCita {
  margin-bottom: 15px;
}

/* FIN OVERRIDES BOOTSTRAP*/

/* LAYOUTS Y ESTILOS GENERALES */
/*Generales de la página*/
body {
  background-color: var(--fondoMain);
}

a {
  text-decoration: none !important;
}

a.download {
  color: var(--colorPrincipal) !important;
}

/*Elementos*/
.logoMain {
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: 1.3rem !important;
}

.textoBotonMenu {
  color: var(--colorSecundario) !important;
  font-weight: bolder;
}

.tituloVisita {
  font-weight: bolder;
}

.containerBotonReserva {
  margin-top: 25px;
  padding-bottom: 100px;
}

.containerChecksVisitantes {
  margin-top: 15px;
}

.nombreAcompañante {
  margin-left: 4px;
}

.labelAuthForm {
  font-weight: bolder;
  color: var(--colorPrincipal);
  margin-right: 10px;
  margin-top: 25px;
}

.labelMensajeOriginal {
  font-weight: bolder;
  color: var(--colorPrincipal);
  margin-right: 10px;
  margin-top: 25px;
  font-size: 20px;
}

.mensajeInicial {
  background-color: var(--principalSoft);
  padding: 15px;
}

.botonAuthUser {
  margin-top: 25px;
}

.formLogin {
  margin-bottom: 25px;
}

.labelLogin {
  color: #0e78db;
  font-weight: bolder;
}

.labelForm {
  color: var(--colorPrincipal);
  font-weight: bolder;
  margin-bottom: 10px;
  margin-right: 6px;
}

.col-5 {
  width: 20% !important;
}

.col-6 {
  margin-right: 18%;
}

.col-7 {
  margin-left: 12rem;
  margin-top: 0.4rem;
  width: 14% !important;
}

.formNewUser {
  margin-top: 15px;
  margin-bottom: 15px;
}

.textoDescriptivo {
  margin-top: 20px;
}

.tituloSeccionUser {
  color: var(--colorSecundario);
  margin-top: 25px;
  font-weight: bolder;
  font-size: 35px;
}

.breadCrumbAl {
  margin-bottom: 2rem;
}

.breadCrumbAl a {
  margin-bottom: 20px;
  color: var(--colorPrincipal);
  font-weight: bold;
}

.loader {
  width: 10%;
  margin-left: 20%;
}

.barraBusqueda {
  margin-bottom: 10px;
}

.textoBarraBusqueda {
  margin-right: 5px;
}

.textoPeligro {
  color: #c51717;
  font-size: 20px;
}

.loaderCenter {
  margin-left: 81%;
}

.mensajeSoft {
  opacity: 0.5;
}

.alerta {
  width: 100%;
}

.docGoogle {
  width: 100%;
  height: 100%;
}

.textImg {
  color: var(--colorPrincipal);
}

.tituloMensaje {
  font-weight: bolder;
  color: var(--colorPrincipal);
}

.fechaMensaje {
  font-style: italic;
  color: var(--principalOscuro);
  float: right;
  opacity: 0.8;
  font-size: 12px;
}

.linkMensaje {
  color: var(--colorPrincipal);
}
.linkMensaje:hover {
  color: var(--colorSecundario);
}

.mensajeNuevo {
  color: var(--colorPrincipal);
  -webkit-animation: opacityAnimation 2s infinite;
  -moz-animation: opacityAnimation 2s infinite;
  -o-animation: opacityAnimation 2s infinite;
  animation: opacityAnimation 2s infinite;
}

.inputMenu {
  width: 100% !important;
}

.tituloSeccionEnc {
  margin-top: 1rem !important;
}

.phoneButton {
  width: 97%;
  margin-left: 0.3rem;
}

.sc-crozmw {
  font-size: 20px !important;
}

.sc-fFGjHI {
  font-size: 20px;
}

.adminAlbum {
  width: 100%;
}

@keyframes opacityAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes opacityAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes opacityAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes opacityAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*Mods font awesome*/
.fa-modalUser {
  color: var(--colorSecundario);
}

.fa-mensaje {
  color: var(--colorPrincipal);
}

.fa-admin {
  color: var(--colorPrincipal);
  text-align: center;
}

.fa-mensajeAbierto {
  color: var(--colorSecundario);
}

/*Media queries*/
@media (max-width: 500px) {
  .col-4 {
    width: 100% !important;
  }

  .msgImgHolder {
    width: 75% !important;
  }

  .col-5 {
    width: 50% !important;
    margin-top: 22px;
  }

  .col-6 {
    width: 100%;
  }

  .col-7 {
    margin-top: 2rem;
    margin-left: 0.8rem;
    width: 25% !important;
    height: 5% !important;
  }

  .logoHolder {
    display: flex;
    justify-content: center;
  }

  .logoMain {
    align-self: center;
    margin-bottom: 2rem;
    margin-left: 11%;
    width: 80%;
  }

  .accordion-header {
    display: none !important;
  }

  .containerMenu {
    margin-top: -1.3rem;
  }
}

.headerCard {
  margin-top: 1rem;
  padding: 1rem;
  margin-bottom: 2rem;
  border: 0px solid rgb(255, 255, 255);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease-in-out;
}

.homeButtonsSmall {
  border: 0px solid rgb(255, 255, 255);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease-in-out;
}

.homeButtonsSmall:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  border: 1px solid var(--colorPrincipal);
  transition: opacity 0.3s ease-in-out;
}

.homeButtons {
  padding-bottom: 2rem;
  margin-bottom: 1rem;
  border: 0px solid rgb(255, 255, 255);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease-in-out;
}

.homeButtons:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  border: 1px solid var(--colorPrincipal);
  transition: opacity 0.3s ease-in-out;
}

@media (min-width: 660px) {
  .msgImgHolder {
    width: 100% !important;
  }
}
